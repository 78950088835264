import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};
/**
 * @description: 获取特殊工时列表时间
 * @param  {*startDate 当周的周一的日期的字符串}
 */
api.getSpecialWeekSummary = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/affairs/specialLogs', params);
};
api.submitSpecialLog = function (menuVisions = {}) {
  return BaseService.postRequest('api/pmf/v1/affairs/specialLogs', filterNull(menuVisions));
};
export default api;
