<template>
  <tab-pane :tabList="tabList" highlight-current-row :activeIndex="activeIndex" @isActive="switchTab" ref="tabsContainer" id="tabsContainer">
    <div class="personal-working__page">
      <div class="record-box">
        <div :class="['header-btns', { progress_header: !tableType }]" ref="headerBtn">
          <el-button
            @click="submitAudit"
            class="savebtn"
            size="medium"
            :type="!canSubmit ? 'info' : 'primary'"
            v-show="!tableType"
            :disabled="!canSubmit"
          >
            {{ $t('personalWorking.submitAuditRecord') }}
          </el-button>
          <div class="header-filter">
            <ChooseWeek ref="chooseWeek" :beginDate="personalWeekRange.startTime" :endDate="personalWeekRange.endTime" @getWeekRange="getWeekRange" />
          </div>
        </div>

        <div class="table_box_con">
          <PersonalWorkingTable
            v-if="workHourTable"
            ref="personalWorkingTable"
            :sumColumn="sumColumn"
            :holidayWeek="holidayWeek"
            :tableData="tableData"
            :summaryData="oneArr"
            :mainHeight="mainHeight"
            :weeklyDay="weeklyDay"
            :tableLoading="tableLoading"
          />
          <el-table v-else :data="tableData" :height="mainHeight" style="width: 100%" ref="progressTable" empty-text=" ">
            <el-table-column prop="projectName" :label="$t('personalWorking.projectName')" width="320"> </el-table-column>
            <el-table-column :label="$t('personalWorking.approvals')" width="180">
              <template slot-scope="scope">
                <div class="statusDes">
                  <div :class="['circle', showStatus('icon', scope.row.status)]"></div>
                  <p>{{ $t(showStatus('name', scope.row.status)) }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="commitAt" :label="$t('personalWorking.submissionTime')"> </el-table-column>
            <el-table-column prop="approverName" :label="$t('personalWorking.reviewer')"> </el-table-column>
            <el-table-column :label="$t('personalWorking.reasonDismiss')">
              <template slot-scope="scope">
                <p>{{ $t(dismissReason(scope.row.reason)) }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="approvalAt" :label="$t('personalWorking.processingTime')"> </el-table-column>
            <el-table-column :label="$t('personalWorking.operation')" align="center" min-width="60">
              <template slot-scope="scope">
                <div>
                  <!-- <el-button size="mini" @click="copyRow(scope.row)">复制</el-button> -->
                  <span @click="checkRow(scope.row)" class="resetSubmit">{{ $t('personalWorking.resubmit') }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <SimpleDialog
      class="defineDialog"
      :title="$t('personalWorking.notice')"
      :visible.sync="dialogVisible"
      width="300px"
      :confirmBtnText="$t('personalWorking.submit')"
      :cancelBtnText="$t('personalWorking.cancel')"
      :confirmBtnLoading="dialogLoading"
      showConfirm
      showCancel
      :show-close="false"
      @onCancel="dialogClose"
      @onSubmit="dialogSubmit"
      :before-close="dialogClose"
    >
      <p v-if="dialogType === 'personalSum'" class="tip">{{ $t('personalWorking.confirmWorkHoursModification') }}</p>
      <p v-else-if="dialogType === 'auditProgress'" class="tip">{{ $t('personalWorking.confirmModification') }}</p>
    </SimpleDialog>
  </tab-pane>
</template>

<script>
import PersonalWorkingTable from './components/PersonalWorkingTable.vue';

import workRecordApi from '@/api/modules/workRecord.api.js';
import personalWorkingApi from '@/api/modules/personalWorking.api.js';

import { debounce } from '@/utils/debounce';
import { getDayAll } from '@/utils/time-tools';
import getUrlParam from '@/utils/getUrlParam';

export default {
  name: 'PersonalWorking',
  components: { PersonalWorkingTable },
  data() {
    return {
      activeIndex: -1,
      hrefTab: null,
      workHourTable: true,
      dialogLoading: false,
      dialogVisible: false,
      dialogType: '',
      firstToInitialize: false,
      firstLoad: false,
      tableType: '',
      holidayWeek: [],
      sumColumn: '工时小计',
      tabList: [
        {
          label: 'personalWorking.progress',
          name: 'progress',
        },
        {
          label: 'personalWorking.summary',
          name: 'personal',
        },
        {
          label: 'personalWorking.projectHours',
          name: 'project',
        },
        {
          label: 'personalWorking.affairsHours',
          name: 'publicAffair',
        },
      ],
      oneArr: [],
      resizeFlag: null,
      projectArr: [],
      tableData: [], //准备渲染表格的数据
      weeklyDay: [], //表格数据的日期
      mainContainerHeight: 0,
      personalWeekRange: { startTime: '', endTime: '' }, //选择周的范围
      tableLoading: false,
      auditRowId: -1,
      canSubmit: false,
    };
  },
  computed: {
    mainHeight() {
      return this.mainContainerHeight - 26;
    },
  },
  created() {
    const createdUrlParam = this.getHrefTab();
    if (createdUrlParam && Object.keys(createdUrlParam).length && this.hrefTab === 'personSummary') {
      this.activeIndex = 1;
    } else {
      this.activeIndex = 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeObserverSize();
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  activated() {
    this.$nextTick(() => {
      this.resizeObserverSize();
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },
  methods: {
    getHrefTab() {
      const urlParam = getUrlParam();
      if (urlParam) {
        this.hrefTab = Object.prototype.hasOwnProperty.call(urlParam, 'tab') ? urlParam['tab'] : null;
      }
      return urlParam;
    },
    dismissReason(reason) {
      if (reason == '提交人自行撤回') {
        return 'personalWorking.dismissReason';
      } else {
        return reason;
      }
    },
    showStatus(name, status) {
      if (name === 'icon') {
        switch (status) {
          case 'committed':
            return 'committed';
          case 'approved':
            return 'approved';
          case 'rejected':
            return 'rejected';
        }
      } else if (name === 'name') {
        switch (status) {
          case 'committed':
            return '待审核';
          case 'approved':
            return '已同意';
          case 'rejected':
            return 'personalWorking.Rejected';
        }
      }
    },
    resizeObserverSize() {
      const resizeDom = debounce(() => {
        this.mainContainerHeight = window.innerHeight - (this.$refs.headerBtn?.offsetHeight ? this.$refs.headerBtn.offsetHeight : 0) - 22 - 88 - 50;
      }, 100);
      resizeDom();
    },
    switchTab(tab) {
      this.workHourTable = true;
      switch (tab) {
        case 'progress':
          this.workHourTable = false;
          this.tableType = 'progress';
          break;
        case 'personal':
          this.tableType = '';
          break;
        case 'project':
          this.tableType = 'project';
          break;
        case 'publicAffair':
          this.tableType = 'affair';
          break;
      }
      if (this.firstToInitialize) {
        this.getTotalRecord(this.personalWeekRange.startTime);
      }
      this.firstToInitialize = true;
    },
    //获取周的时间
    async getWeekRange(weekData) {
      const { weekRangeData, urlParam } = weekData;
      /* 区分链接跳转进入和正常点击进入的当前周时间处理 */
      this.personalWeekRange = weekRangeData;
      if (urlParam && Object.keys(urlParam).length && !this.tableType && !this.firstLoad) {
        this.firstLoad = true;
        //  && tab === 'personSummary'
        this.personalWeekRange.startTime = urlParam['beginDate'];
        this.personalWeekRange.endTime = urlParam['endDate'];
        this.getTotalRecord(urlParam['beginDate'], urlParam['endDate']);
      } else {
        this.personalWeekRange.startTime = this.personalWeekRange.startTime.split(' ')[0];
        this.personalWeekRange.endTime = this.personalWeekRange.endTime.split(' ')[0];
        this.getTotalRecord(this.personalWeekRange.startTime);
      }
      const startTime = this.personalWeekRange.startTime.replace(/-/g, '/');
      const endTime = this.personalWeekRange.endTime.replace(/-/g, '/');
      this.weeklyDay = getDayAll(startTime, endTime).map(i => i.split('-').splice(1).join('-'));
    },
    checkRow(row) {
      this.dialogVisible = true;
      this.dialogType = 'auditProgress';
      this.auditRowId = row.id;
    },
    submitAudit() {
      this.dialogVisible = true;
      this.dialogType = 'personalSum';
    },
    async dialogSubmit() {
      this.dialogLoading = true;
      if (this.dialogType === 'auditProgress') {
        let [data] = await personalWorkingApi.restartSubmitAudit({ id: this.auditRowId });
        if (!data) {
          this.dialogLoading = false;
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
          this.getTotalRecord(this.personalWeekRange.startTime);
          this.dialogClose();
        }
        return;
      }
      let [data] = await personalWorkingApi.submitWorkRecord({
        beginDate: this.personalWeekRange.startTime,
        endDate: this.personalWeekRange.endTime,
      });
      if (!data) {
        this.dialogLoading = false;
        this.getTotalRecord(this.personalWeekRange.startTime);

        this.$message.success('提交成功');
        this.dialogClose();
      }
    },
    dialogClose() {
      this.dialogLoading = false;
      this.dialogVisible = false;
    },
    async getTotalRecord(weekStart, weekEnd) {
      this.tableLoading = true;
      const type = this.tableType;
      if (type === 'progress') {
        let [data] = await personalWorkingApi.getProgressRecord({
          beginDate: weekStart,
          endDate: weekEnd ?? this.personalWeekRange.endTime,
        });
        this.tableData = [...data.approvals];
        this.tableLoading = false;
        return;
      }
      let [data] = await personalWorkingApi.getPersonWeekSummary({ weekStart, type });
      let [canSubmitData] = await personalWorkingApi.checkCanSubmit({ beginDate: weekStart, endDate: weekEnd ?? this.personalWeekRange.endTime });
      this.canSubmit = canSubmitData.canCommit;
      if (!data) return;
      let endDate = weekEnd ? weekEnd : this.personalWeekRange.endTime;
      let [dateData] = await workRecordApi.getholidayLog({
        beginDate: weekStart,
        endDate,
      });
      this.holidayWeek = [...dateData.dates];
      data.project = data.project || [];
      this.projectArr = data.project;
      data.weekSummary = data.weekSummary || {};
      data.totalDuration = data.totalDuration ?? 0;
      //映射为table需要的数据格式
      this.oneArr = [];
      this.oneArr.push({
        projectId: 0,
        projectName: '',
        serviceItem: '项目工时总计',
        mon: data.weekSummary.duration[0] ?? 0,
        tue: data.weekSummary.duration[1] ?? 0,
        wed: data.weekSummary.duration[2] ?? 0,
        thu: data.weekSummary.duration[3] ?? 0,
        fri: data.weekSummary.duration[4] ?? 0,
        sat: data.weekSummary.duration[5] ?? 0,
        sun: data.weekSummary.duration[6] ?? 0,
        weekSum: data.totalDuration ?? 0,
      });

      let table = [];
      for (let i = 0; i < data.project.length; i++) {
        for (let j = 0; j < data.project[i].serving.length; j++) {
          table.push({
            projectId: data.project[i].id,
            projectName: data.project[i].name,
            serviceItem: data.project[i].serving[j].name,
            mon: this.filterWeekDaysDuration(data.project[i].serving[j].log, 1),
            tue: this.filterWeekDaysDuration(data.project[i].serving[j].log, 2),
            wed: this.filterWeekDaysDuration(data.project[i].serving[j].log, 3),
            thu: this.filterWeekDaysDuration(data.project[i].serving[j].log, 4),
            fri: this.filterWeekDaysDuration(data.project[i].serving[j].log, 5),
            sat: this.filterWeekDaysDuration(data.project[i].serving[j].log, 6),
            sun: this.filterWeekDaysDuration(data.project[i].serving[j].log, 7),
            weekSum: data.project[i].serving[j].total,
          });
        }
      }
      // this.tableData = this.table.sort(function (a, b) {
      //   a.projectId - b.projectId;
      // });

      this.tableData = [...table];
      if (this.projectArr.length == 0) {
        this.tableData = [];
      }
      this.tableLoading = false;
    },

    filterWeekDaysDuration(log, weekNumber) {
      if (log) {
        let hasData = log.find(w => w.weekNumber === weekNumber);
        if (hasData) {
          hasData.duration = hasData.duration ?? 0;
          return hasData;
        } else {
          return { duration: 0, id: null, weekNumber };
        }
      } else {
        return { duration: 0, id: null, weekNumber };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#tabsContainer {
  ::v-deep .el-dialog__header {
    height: 50px;
  }

  .personal-working__page {
    ::v-deep .el-table__row {
      .el-table__cell {
        height: 41px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    //table_header
    ::v-deep.el-table__header-wrapper {
      border-radius: 4px;

      .el-table__header th.el-table__cell {
        background-color: #f8f8f8f9;
        color: #515a6e;
        font-weight: 600;
        padding: 6px 0;
        height: 60px;
        box-sizing: border-box;
      }
    }

    ::v-deep.el-table th.el-table__cell > .cell {
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      vertical-align: middle;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
    }

    ::v-deep.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: none;
    }

    ::v-deep .el-table__footer {
      .has-gutter {
        .el-table__cell {
          background: #f8f8f8f9;
          color: #515a6e !important;
          padding: 1px 0 !important;
          height: 38px !important;
          font-weight: 600;
        }
      }
    }

    ::v-deep.el-table {
      border: 1px solid #e8eaec;
      border-bottom: none;
      border-radius: 4px;
    }

    .record-box {
      background-color: #fff;
      padding: 9px 31px 12px;
      border-radius: 2px;

      .header-btns {
        width: 100%;
        display: flex;
        flex: auto;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0;
        margin-bottom: 11px;
        height: 32px;
        font-size: 14px;

        .savebtn {
          height: 32px;
          padding-top: 0;
          padding-bottom: 0;
          // line-height: 32px;
        }

        .header-filter {
          display: flex;
          align-items: center;
          height: 100%;
          padding-right: 16px;
        }
      }

      .progress_header {
        flex: 1;
        align-items: center;
        justify-content: space-between;
      }

      .table_box_con {
        width: 100%;

        .statusDes {
          margin-left: 8px;

          .circle {
            position: absolute;
            top: calc(50% - 3px);
            left: 8px;
            border-radius: 50%;
            width: 6px;
            height: 6px;
          }

          .committed {
            background: #2d8cf0;
          }

          .approved {
            background: #58ba73;
          }

          .rejected {
            background: #da4f2a;
          }
        }

        .resetSubmit {
          cursor: pointer;
          color: #2d8cf0;
        }
      }
    }
  }

  .defineDialog {
    .tip {
      line-height: 22px;
      margin: 0 auto;
      padding: 20px 0;
      width: 260px;
    }
  }

  /*   .personal-working__page::before {
    content: '';
    position: absolute;
    top: 67px;
    width: calc(100% - 115px);
    transform: translate(-50%);
    left: 50%;
    height: 1px;
    background: #dcdee2;
  } */
}
</style>
