<template>
  <div id="specialWorkingHours" v-if="!authPick">
    <div class="record-box" :style="{ height: mainDomHeight + 'px' }">
      <div class="header-btns" ref="headerBtn">
        <div class="header-filter">
          <ChooseWeek ref="chooseWeek" @getWeekRange="getWeekRange" />
          <el-button @click="saveWorkLog" class="savebtn create" size="medium" type="primary">{{ $t('workRecord.save') }}</el-button>
        </div>
      </div>

      <EditServiceItemTable
        :tableHeight="tableHeight"
        :tableLoading.sync="tableLoading"
        :serviceTableData="serviceTableData"
        :deletePermission="sponsorDelete"
        :holidayWeek="holidayWeek"
        :updatePermission="true"
        :totalDuration="totalDuration"
        :changeItems="changeItems"
        @changeObjsData="changeObjsData"
        :requiredIndex="requiredIndex"
        @searchForm="searchForm"
        :inputDisabled="inputDisabled"
      ></EditServiceItemTable>
    </div>
  </div>

  <div v-else>
    <Empty v-if="shouldShowErrimg" :class="{ public_Management_err: shouldShowErrimg }" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import specialWorkingApi from '@/api/modules/specialWorking.api.js';
import workRecordApi from '@/api/modules/workRecord.api.js';
import { debounce } from '@/utils/debounce';
import Empty from '@/components/Empty/index.vue';
import EditServiceItemTable from './components/EditServiceItemTable';
import { getDayAll } from '@/utils/time-tools';
export default {
  components: {
    EditServiceItemTable,
    Empty,
  },
  name: 'specialWorkingHours',
  computed: {
    sponsorCreate() {
      return this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementCreate;
    },
    sponsorDelete() {
      return this.$store.state.app.userConfig.permissionsKeys?.SponsorManagementDelete;
    },
  },
  data() {
    return {
      tableHeight: 0,
      totalDuration: 0,
      weekId: '',
      filterData: [],
      holidayWeek: [],
      weekRange: {}, //选择周的范围
      requiredIndex: [],
      serviceTableData: [],
      count: 0,
      resizeFlag: null,
      changeItems: [], //被改变的单元格数据
      choosedContractItem: {}, //当前选择的事项
      showEmpty: false,
      tableLoading: false,
      inputDisabled: false,
      dataForm: {
        page: 1,
        pagesize: 20,
        nameContains: '',
      },
      mainDomHeight: 0,
      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.calTableDom();
    });
    window.addEventListener('resize', this.calTableDom);
  },
  activated() {
    this.$nextTick(() => {
      this.calTableDom();
    });
    window.addEventListener('resize', this.calTableDom);
  },
  deactivated() {
    window.removeEventListener('resize', this.calTableDom);
  },
  methods: {
    calTableDom() {
      const resizeDom = debounce(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 5 + 16 + 24;
        this.mainDomHeight = window.innerHeight - this.otherDomHeight + 24;
        this.tableHeight = this.mainDomHeight - 24 - 50;
      }, 0);
      resizeDom();
    },

    /*     calTableDom() {
      const resizeDom = debounce(() => {
        this.mainDomHeight = window.innerHeight - 32 - 50 - 20;
      }, 100);
      resizeDom();
    }, */
    // 去重
    unique(arr) {
      // let x = new Set(arr)
      // return [...x]
      let x = {};
      let arrData = arr.reduce((cur, next) => {
        x[next.subIndex] ? '' : (x[next.subIndex] = true && cur.push(next));
        return cur;
      }, []);
      return arrData;
    },

    //获取周的时间
    getWeekRange(weekRange) {
      const { weekRangeData } = weekRange;
      const fn = async () => {
        this.weekRange = weekRangeData;
        await this.refreshData(this.weekRange.startTime.split(' ')[0]);
        const startTime = this.weekRange.startTime.split(' ')[0].replace(/-/g, '/');
        const endTime = this.weekRange.endTime.split(' ')[0].replace(/-/g, '/');
        this.weeklyDay = getDayAll(startTime, endTime).map(i => i.split('-').splice(1).join('-'));
      };
      if (this.changeItems.length) {
        // const catchfn = () => this.$refs['chooseWeek'].setLastChoose();
        this.noSave(fn);
      } else {
        fn();
      }
    },
    changeObjsData(proItem, proData) {
      this.changeItems = [...proItem];
      this.changeItems = this.unique(this.changeItems);
      this.filterData = [...proData];
    },
    noSave(dofn, catchfn) {
      this.$confirm(this.$t('commonVariables.saveWarnTips'), this.$t('commonVariables.notice'), {
        confirmButtonText: this.$t('commonVariables.confirmDelete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
      })
        .then(() => {
          dofn();
          this.changeItems = [];
        })
        .catch(() => {
          catchfn && catchfn();
        });
    },
    /*     async deleteData(id) {
      try {
        await resourceManagement.sponsorDeleteData(id);
        this.refreshData();
      } catch (err) {
        this.$message.error('保存项目失败！');
        return;
      }
    }, */
    async refreshData(startDate) {
      console.log(startDate);
      this.tableLoading = true;
      let endDate = this.weekRange.endTime.split(' ')[0];
      let [dateData] = await workRecordApi.getholidayLog({
        beginDate: startDate,
        endDate,
      });
      this.holidayWeek = [...dateData.dates];
      specialWorkingApi
        .getSpecialWeekSummary({ startDate: startDate })
        .then(res => {
          this.authPick = Number(res[2].status) === 403;
          if (this.authPick) {
            this.errImg = require('@/assets/noquanxian.png');
            this.errInfo = '抱歉，您暂无权限哦～';
            this.shouldShowErrimg = true;
            this.$message.closeAll();
            return;
          }
          if (res[2].status !== 200) {
            this.serviceTableData = [];
            this.showEmpty = true;
            return;
          }

          this.serviceTableData = [...res[0].days];
          this.weekId = res[0].weekId;
          this.inputDisabled = res[0].disabled;
          this.totalDuration = res[0].totalDuration;
          this.tableLoading = false;
          this.showEmpty = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    /*     //添加服务项
    addServiceItem() {
      if (this.addSum) {
        this.$message.error('请先保存新增行内容后再添加');
        return;
      }
      this.serviceTableData.unshift({ name: '', fullname: '', remark: '', type: 'new' });
      this.addSum = true;
    }, */
    //保存改动的项目工作记录
    async saveWorkLog() {
      const checkTimeOut = this.filterData.find(item => {
        let calHours = 0;
        return item.logs.find(val => {
          calHours = calHours + val.duration;
          if (calHours > 24) {
            return true;
          } else {
            return false;
          }
        });
      });
      if (checkTimeOut) {
        this.$message.error('存在不符合填写条件的工时，请修改后再次提交');
        return;
      }
      if (!this.changeItems.length) {
        this.$message.warning('没有改动项');
        return;
      }
      this.requiredIndex = [];
      let submitData = { weekId: '', logs: [] };

      this.filterData.forEach(item => {
        let filterLog = item.logs;
        let nums = filterLog.filter(i => {
          return (i.duration && !i.desc) || (!i.duration && i.desc);
        });
        this.requiredIndex = this.requiredIndex.concat(nums);
        if (filterLog.length <= 1 && !filterLog[0].desc) {
          return;
        }
        filterLog = filterLog.filter(idx => {
          if (!idx.duration && !idx.desc && idx.desc !== 0) {
            return;
          } else {
            if (!idx.duration) idx.duration = undefined;
            return idx;
          }
        });
        submitData.logs = submitData.logs.concat(filterLog);
      });
      if (this.requiredIndex.length) {
        this.$message.error('存在工作量或工作内容未填写');
        return;
      }

      submitData.weekId = this.weekId;
      const res = await specialWorkingApi.submitSpecialLog(submitData);
      if (res[2].status === 200) {
        this.$message.success('保存成功');
        this.filterData = [];
        this.changeItems = [];
        this.refreshData(this.weekRange.startTime.split(' ')[0]);
      }
    },
    searchForm() {
      const searchSubmit = debounce(this.refreshData, 800);
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      searchSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';
#specialWorkingHours {
  overflow: hidden;
  margin: 0 24px;
  ::v-deep .workContentColumn {
    color: #515a6e;
    // border-bottom: none !important;
  }
  ::v-deep .el-table__row {
    .el-table__cell {
      height: 46px;
      padding-top: 0.5px;
      padding-bottom: 0.5px;
    }
  }
  .record-box {
    overflow-y: auto;
    background-color: #fff;
    padding: 16px 24px 0;
    border-radius: 2px;
    .header-btns {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
      margin-bottom: 0;
      height: 32px;
      font-size: 14px;
      .header-filter {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 0;
        .savebtn {
          height: 32px;
          padding-top: 0;
          padding-bottom: 0;
          // line-height: 30px;
        }
        .create {
          margin-left: 16px;
        }
      }
    }

    .table_box_con {
      width: 100%;
    }
  }
}
</style>
