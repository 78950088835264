<template>
  <el-table
    ref="table"
    class="personalTable"
    :class="{ show_empty: showEmpty }"
    v-loading="tableLoading"
    :data="formData"
    :row-style="rowMeshStyle"
    :cell-style="cellMeshStyle"
    :span-method="objectSpanMethod"
    border
    :max-height="mainHeight"
    empty-text=" "
  >
    <el-table-column :label="$t('personalWorking.project')" min-width="200" align="left">
      <template slot-scope="scope">
        <div style="display: flex; align-content: center">
          <span>{{ $t(rowNameFilter(scope.row.projectName)) }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('personalWorking.serviceItem')" min-width="200" align="left">
      <template slot-scope="scope">
        <div>{{ scope.row.serviceItem }}</div>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      v-for="(item, index) in weeklySums"
      :key="item.simpDes"
      :prop="item.simpDes"
      :min-width="$i18n.locale == 'en' ? '80' : '60'"
    >
      <template #header>
        <div :class="['header-text', headerCellHoliday(item)]">
          <span> {{ $t('personalWorking.' + item.fullDes) }}</span>
          <div class="dayStyle">{{ weeklyDay[index] }}</div>
        </div>
      </template>
      <template slot-scope="scope">
        <el-popover
          v-if="scope.row[item.simpDes].remark"
          placement="top"
          width="240"
          trigger="hover"
          :content="scope.row[item.simpDes].remark"
          popper-class="poppers"
        >
          <span class="inputShadow popperRemarks" slot="reference">{{ scope.row[item.simpDes].duration }}</span>
        </el-popover>
        <div v-else-if="scope.row[item.simpDes].duration || scope.row[item.simpDes].duration === 0">
          {{ scope.row[item.simpDes].duration }}
        </div>
        <div v-else>{{ scope.row[item.simpDes] }}</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('personalWorking.myWeekStatistics')" align="center">
      <template slot-scope="scope">
        <div>{{ scope.row.weekSum }}</div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'PersonalWorkingTable',
  computed: {
    rowNameFilter() {
      return proName => {
        switch (proName) {
          case '工时小计':
            return 'personalWorking.SubtotalDuration';
          case '工时合计':
            return 'personalWorking.TotalDuration';
          default:
            return proName;
        }
      };
    },
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    holidayWeek: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sumColumn: {
      type: String,
      default: '',
    },
    // 总计数据
    summaryData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {
    // 获取合计行的对应列表头名
    this.sumColumnName = this.sumColumn;
  },

  data() {
    return {
      sumColumnName: undefined,
      formData: [],
      weeklySums: [
        { fullDes: 'monday', simpDes: 'mon', weekIndex: 0 },
        { fullDes: 'tuesday', simpDes: 'tue', weekIndex: 1 },
        { fullDes: 'wednesday', simpDes: 'wed', weekIndex: 2 },
        { fullDes: 'thursday', simpDes: 'thu', weekIndex: 3 },
        { fullDes: 'friday', simpDes: 'fri', weekIndex: 4 },
        { fullDes: 'saturday', simpDes: 'sat', weekIndex: 5 },
        { fullDes: 'sunday', simpDes: 'sun', weekIndex: 6 },
      ],
      spanArr: [],

      pos: 0,

      showEmpty: false,
    };
  },
  watch: {
    tableData: {
      deep: true,
      immediate: false,
      handler(val) {
        this.getSummaries(val);
      },
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs['table'].doLayout();
    });
  },

  methods: {
    async getSummaries(val) {
      const filterData = JSON.parse(JSON.stringify(val));
      !val.length ? (this.showEmpty = true) : (this.showEmpty = false);

      let sumObj = Object.assign({}, this.summaryData[0]);
      sumObj['projectName'] = '工时合计';
      sumObj['projectId'] = -1;
      filterData.push(sumObj);
      await this.getSpanArr(filterData, 'projectId');
    },

    headerCellHoliday(item) {
      if (!this.holidayWeek.length) {
        return;
      }
      const weeklyIdx = item.weekIndex;
      let wekData = this.holidayWeek[weeklyIdx];
      // 普通工作日适用通用规则
      if (wekData.type === 'holiday') {
        return 'holidayHeader';
      }
      if (wekData.isWorkday && wekData.type === 'weekend') {
        return 'makeUpWorkDay';
      }
    },
    /*     tableRowStyleName({ row }) {
      if (row.serviceItem == '项目工时小计') {
        return 'background: #DFF1FF;!important;';
      }
    }, */

    //函数groupBy有两个形参，data 为 表格数据 ， params 为需要合并的字段
    groupBy(data, params) {
      const groups = {};
      data.forEach(v => {
        // 根据模拟数据 是通过a字段 来分组，获取data中的传入的params属性对应的属性值 ，放入数组中：["2222"]，再将属性值转换为json字符串：'["2222"]'
        const group = JSON.stringify(v[params]);

        // 把group作为groups的key，初始化value，循环时找到相同的v[params]时不变
        groups[group] = groups[group] || [];
        // 将对应找到的值作为value放入数组中
        groups[group].push(v);
      });

      // 返回处理好的二维数组 （如果想返回groupBy形式的数据只返回groups即可）
      return Object.values(groups);
    },

    //处理数据为合并表格做准备
    async getSpanArr(data, params) {
      this.formData = [];
      // 接收重构数组
      let arr = [];

      // 设置索引
      let pos = 0;

      // 控制合并的数组
      this.spanArr = [];

      if (!data.length) {
        return;
      }
      // arr 处理
      await this.groupBy(data, params).map(v => (arr = arr.concat(v)));
      //  遍历处理项目小计行列，使其独立

      arr.forEach(res => {
        if (res.serviceItem === this.sumColumnName) {
          res.projectId = ' ';
          res.projectName = this.sumColumnName;
          res.serviceItem = ' ';
        } else if (res.serviceItem === '项目工时总计') {
          res.serviceItem = ' ';
          res.projectId = '1';
        }
      });
      // 在完成对数据的重排之后，更新tableData的数据
      this.formData = this.formData.concat(arr);

      // spanArr 处理
      const redata = arr.map(v => v[params]);
      redata.reduce((old, cur, i) => {
        if (i === 0) {
          this.spanArr.push(1);
          pos = 0;
        } else {
          if (cur === old) {
            this.spanArr[pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            pos = i;
          }
        }
        return cur;
      }, {});
    },
    cellMeshStyle({ row, column, columnIndex } = {}) {
      if (columnIndex < 2) {
        return;
      }
      if (
        row.projectName != '工时合计' &&
        row.projectName != 'Total duration' &&
        row.projectName != '工时小计' &&
        row.projectName != 'Subtotal of duration'
      ) {
        return;
      }
      let overCountSumRol = {
        fontWeight: 600,
        background: '#FFE0DD',
        color: '#515A6E',
      };
      let unOverCountSumRol = {
        fontWeight: 600,
        background: '#FFF4C3',
        color: '#515A6E',
      };

      if (row.weekSum > 44 && (column.label == '个人周统计' || column.label == 'My Week Statistics')) {
        return overCountSumRol;
      }
      if ((row.projectName === '工时合计' || row.projectName == 'Total duration') && row[column.property] < 6) {
        const isWorkday = this.headerCellHoliday(this.weeklySums.find(val => val.simpDes === column.property));
        if (isWorkday === 'makeUpWorkDay' || (!isWorkday && column.property !== 'sun' && column.property !== 'sat')) {
          return unOverCountSumRol;
        }
      }
      if (row[column.property]?.duration > 8 || row[column.property] > 8) {
        return overCountSumRol;
      }
    },
    rowMeshStyle({ row } = {}) {
      let countSumRol = {
        fontWeight: 600,
        background: ' #f8f8f8f9',
        color: '#515a6e',
      };
      if (
        row.projectName === '工时小计' ||
        row.projectName === '工时合计' ||
        row.projectName === 'Total duration' ||
        row.projectName === 'Subtotal of duration'
      ) {
        return countSumRol;
      }
    },
    //合并列表格
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-table th.el-table__cell > .cell {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
::v-deep.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background: none;
}
::v-deep .el-table__footer {
  .has-gutter {
    .el-table__cell {
      background: #fbfcfb !important;
      color: #515a6e !important;
      padding: 1px 0 !important;
      height: 38px !important;
      font-weight: 600;
    }
  }
}
::v-deep.el-table {
  border: 1px solid #e8eaec;
  border-bottom: none;
  border-radius: 4px;
  color: #515a6e;
  .header-text {
    padding-top: 3px;
    margin: 0 auto;
    position: relative;
    width: 80%;
    height: 50px;
    border-radius: 4px;
    text-align: center;

    .dayStyle {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .header-text:hover {
    cursor: pointer;
    background: rgba(232, 233, 236, 0.5);
    /*   .cirStyle {
    background: rgba(232, 233, 236, 0.3);
  } */
  }
  // 假期
  .holidayHeader {
    line-height: 22px;
    padding-top: 5px;
    padding-left: 5px;
    background-color: rgba(255, 236, 234, 1);
    color: rgba(219, 79, 42, 1) !important;
  }
  .holidayHeader:hover {
    background-color: rgba(255, 236, 234, 0.6) !important;
  }
  .holidayHeader::after {
    font-weight: normal;
    content: '休';
    font-size: 10px;
    position: absolute;
    top: 1px;
    left: 2px;
  }
  .makeUpWorkDay {
    line-height: 22px;
    padding-top: 5px;
    padding-left: 5px;
    background-color: rgba(119, 185, 255, 0.2);
    color: #2d8cf0 !important;
  }
  .makeUpWorkDay:hover {
    background-color: rgba(119, 185, 255, 0.12);
  }
  .makeUpWorkDay::after {
    font-weight: normal;
    content: '班';
    font-size: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .popperRemarks {
    cursor: pointer;
    display: inline-block;
    width: 52px;
    height: 29px;

    background: rgba(24, 144, 255, 0.05);
    border-radius: 2px;
    font-size: 14px;
    color: #2d8cf0;
    line-height: 29px;
  }
  .poppers {
    padding: 18px 20px;
    .title {
      margin: 4px 0;
      margin-top: 0;
      font-size: 14px;
      margin-left: 2px;
    }
    .el-textarea {
      font-size: 12px;
      width: 245px;
    }
  }
  //table_header
  .el-table__header-wrapper {
    border-radius: 4px;
    .el-table__header th.el-table__cell {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
      padding: 6px 0;
      height: 60px;
      box-sizing: border-box;
    }
  }
  .inputShadow {
    height: 29px;
    line-height: 29px;
    margin: 0;
  }
  .inputShadow:hover {
    border: none;
    text-align: center;
    margin: 0;
    background-color: #fff;
  }
  /*   .el-table__empty-block {
    margin: 0 auto;
    height: 500px !important;
    margin-bottom: 150px;

    .el-table__empty-text {
      background-image: url('../../../assets/noAuditRecord.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 240px;
      height: 206px;
      position: relative;
      &::before {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        transform: translateX(20px);
        bottom: -80px;
        content: '暂时没有任何数据呢～';
      }
    }
  } */
}
</style>
