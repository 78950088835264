<template>
  <div class="editServiceTable" ref="editServiceTable">
    <el-table
      ref="specialTable"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="tableData"
      v-on="$listeners"
      @row-click="rowClick"
      :show-summary="isShowSummary"
      border
      :max-height="tableHeight"
      :summary-method="getSummaries"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :header-cell-class-name="headerCellMeshStyle"
      empty-text=" "
    >
      <el-table-column :label="$t('specialWorkingHours.date')" class-name="timeColumn" align="center" min-width="50">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.logs" :key="item.subIndex" class="cell-date">
            <span>{{ item.showDate }}</span>
            <div :class="holidayShow(item, scope.row.index)"></div>
            <div alt="增加数据" class="addSum" v-if="index === 0 && scope.row.logs.length < 5" @click="addSum(scope)"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('specialWorkingHours.workload')" align="center" min-width="50" class-name="workloadColumn">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.logs" :key="item.subIndex">
            <span v-if="!choosedThisRow(scope.row.index, 'duration', true, index)">{{ item.duration }}</span>
            <el-input
              v-else
              size="mini"
              resize="none"
              :disabled="inputDisabled"
              @change="toRecordChanges(index, scope.row.index, 'duration')"
              :class="['hours-input', { 'border-input': borderInputCal(index, scope.row) }]"
              v-model="item.duration"
              @focus="focusRow(index, scope.row.index)"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('specialWorkingHours.workContent')" align="left" min-width="220" class-name="workContentColumn">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.logs" :key="item.subIndex">
            <span v-if="!choosedThisRow(scope.row.index, 'desc', true, index)">{{ item.desc }}</span>
            <div v-else :class="['editInput', { 'border-input': borderInputCal(index, scope.row) }]">
              <el-input
                size="mini"
                :autosize="{ minRows: 1, maxRows: 3 }"
                resize="none"
                :disabled="inputDisabled"
                @change="toRecordChanges(index, scope.row.index)"
                @focus="focusRow(index, scope.row.index)"
                type="textarea"
                class="remark-input"
                v-model="item.desc"
              />
              <img
                v-show="deletePermission && iconShow(index, scope.row)"
                @click="deleteRow(index, scope.row.index)"
                class="deleteRow"
                src="@/assets/tabIcon/delete_icon.png"
              />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    pagesize: Number,
    count: Number,
    // 对应必填项的项目数组
    requiredIndex: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableHeight: {
      type: Number,
      default: 0,
    },
    holidayWeek: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editDiago: {
      type: Boolean,
      default: false,
    },
    copyRow: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    totalDuration: {
      type: Number,
      default: 0,
    },
    saveKey: Boolean,
    applyComp: String,

    tableLoading: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    borderInputCal() {
      return (idx, row) => {
        let temIdx = idx;
        return ++temIdx != row.logs.length;
      };
    },
  },
  data() {
    return {
      isShowSummary: false,
      addKey: false,
      tableData: [],
      resizeFlag: null,
      subIndex: undefined,
      rowIndex: undefined,
      showEmpty: false,
      subChangeItems: [],
      weeks: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    };
  },

  watch: {
    serviceTableData: {
      deep: false,
      immediate: false,
      handler(newVal) {
        this.$nextTick(() => {
          if (!newVal.length) {
            return;
          }
          for (let i = 0; i < 7; i++) {
            let dates = newVal[i].date.slice(5, newVal[i].date.length);
            if (!newVal[i].logs.length) {
              newVal[i].logs.push({
                id: undefined,
                subIndex: 0,
                showDate: (dates += ' ' + this.weeks[i]),
                date: newVal[i].date,
                duration: undefined,
                desc: '',
                remark: '',
              });
            } else {
              newVal[i].logs[0].showDate = dates += ' ' + this.weeks[i];
              newVal[i].logs = newVal[i].logs.map((item, index) => {
                item.subIndex = index;
                item.date = newVal[i].date;
                return item;
              });
            }
          }
          this.subIndex = undefined;
          this.rowIndex = undefined;
          this.subChangeItems = [];
          this.tableData = JSON.parse(JSON.stringify(newVal));
          this.isShowSummary = true;
        });
      },
    },
    saveKey(newVal) {
      if (newVal) {
        this.rowIndex = undefined;
        this.subIndex = undefined;
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.specialTable.doLayout();
    });
  },
  methods: {
    holidayShow(val, rowIndex) {
      if (!this.holidayWeek.length) {
        return;
      }
      if (val.subIndex !== 0) {
        return;
      }

      const weeklyIdx = rowIndex;
      let wekData = this.holidayWeek[weeklyIdx];
      // 普通工作日适用通用规则
      if (wekData.type === 'holiday') {
        return 'holidayHeader';
      }
      if (wekData.isWorkday && wekData.type === 'weekend') {
        return 'makeUpWorkDay';
      }
    },
    getSummaries() {
      let sums = [];
      sums[0] = '个人周统计';
      sums[1] = this.totalDuration;
      return sums;
    },
    // 这里不负责检测增删，增删只在删除的检测方法之中进行处理
    toRecordChanges(idx, rowIdx, key) {
      if (key === 'duration') {
        if (!this.checkNum(idx, rowIdx)) {
          this.setChangeItems(idx, rowIdx);
          return;
        }
      }
      let filterItem = null;

      this.serviceTableData.find((item, index) => {
        if (index === rowIdx) {
          filterItem = item.logs.find(idm => {
            return idm.subIndex === idx;
          });
        }
      });
      if (!filterItem) {
        return;
      }

      if (this.tableData[rowIdx].logs[idx].duration !== filterItem.duration || this.tableData[rowIdx].logs[idx].desc !== filterItem.desc) {
        this.setChangeItems(idx, rowIdx);
      }
    },
    addSum(scope) {
      const addFn = () => {
        this.addKey = true;
        const length = this.tableData[scope.$index].logs.length - 1;
        let lastIndex = this.tableData[scope.$index].logs[length].subIndex;
        this.tableData[scope.$index].logs.push({
          id: undefined,
          subIndex: ++lastIndex,
          date: this.tableData[scope.$index].date,
          duration: '',
          desc: '',
          remark: '',
        });
        this.setChangeItems(this.tableData[scope.$index].logs.length - 1, scope.$index);
      };

      if (!this.addKey) {
        addFn();
      }
    },
    setChangeItems(idx, rowIdx) {
      this.$nextTick(() => {
        if (!this.subChangeItems.length) {
          this.subChangeItems.push(this.tableData[rowIdx].logs[idx]);
          this.$emit('changeObjsData', this.subChangeItems, this.tableData);
          this.addKey = false;
          return;
        }
        this.subChangeItems.forEach((item, index) => {
          let date = item.date;
          let recordIndex = item.subIndex;
          // 注意，此处是为了反复填写同一项时的去重操作，以最后的改动为最新版本
          if (this.tableData[rowIdx].logs[idx].date === date && this.tableData[rowIdx].logs[idx].subIndex === recordIndex) {
            this.subChangeItems.splice(index, 1);
          }
          this.subChangeItems.push(this.tableData[rowIdx].logs[idx]);
        });
        this.$emit('changeObjsData', this.subChangeItems, this.tableData);
        this.addKey = false;
      });
    },
    //检查输入的值
    checkNum(idx, rowIdx) {
      const thisVal = this.tableData[rowIdx].logs[idx].duration;
      if (thisVal == '') {
        return;
      }

      const regNum = /^[0-9]+([.][5]{1})?$/;
      if (regNum.test(thisVal)) {
        this.tableData[rowIdx].logs[idx].duration = Number(thisVal);
      } else {
        this.tableData[rowIdx].logs[idx].duration = 0;
        this.$message.error('数值不合规，工时精度为半小时，例：0.5，1，1.5，8');
        return;
      }
      if (thisVal < 0 || thisVal > 168) {
        this.tableData[rowIdx].logs[idx].duration = 0;
        return;
      }
      return 'success';
    },
    iconShow(index, row) {
      if (row.logs.length <= 1) {
        return;
      }
      return this.subIndex === index && this.rowIndex === row.index;
    },

    headerCellMeshStyle(cell) {
      const isCheck = cell.row.find(item => {
        return item.label === '操作';
      });
      if (isCheck) {
        return 'fixed-cell';
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      return 'border-bottom-clear';
    },

    async editRow(index, rows) {
      this.$emit('editServiceItem', rows[index]);
    },
    async deleteRow(delIndex, delRowIndex) {
      this.$confirm('<p>此操作将删除该服务项, 是否继续?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          // 首项处理
          if (!delIndex) {
            this.tableData[delRowIndex].logs[1].showDate = this.tableData[delRowIndex].logs[delIndex].showDate;
          }
          this.subChangeItems.forEach((item, index) => {
            let date = item?.date;
            let recordIndex = item?.subIndex;
            let currentIndex = index;

            // 注意，此处是为了反复填写同一项时的去重操作，以最后的改动为最新版本

            if (this.tableData[delRowIndex].logs[delIndex].date === date && this.tableData[delRowIndex].logs[delIndex].subIndex === recordIndex) {
              this.subChangeItems.splice(currentIndex, 1);
            }
            if (this.tableData[delRowIndex].logs[delIndex].id) {
              this.subChangeItems.push(this.tableData[delRowIndex].logs[delIndex]);
            }
          });
          this.$emit('changeObjsData', this.subChangeItems, this.tableData);
          this.tableData[delRowIndex].logs.splice(delIndex, 1);
          this.tableData[delRowIndex].logs.map((item, index) => {
            return (item.subIndex = index);
          });
          this.$message({
            type: 'success',
            message: '已删除',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    indexMethod(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    focusRow(index, rowIndex) {
      this.subIndex = index;
      this.rowIndex = rowIndex;
    },
    //记录点击了哪一行
    rowClick() {
      this.$emit('update:saveKey', false);
    },
    /* 把选中的行的某些单元格替换为输入框 */
    choosedThisRow(idx, key, edit, index) {
      if (this.editDiago || !this.updatePermission) {
        return;
      }
      // 编辑情况下直接编辑
      if (this.tableData[idx].logs[index]) {
        return edit;
      }
      // 新建项目情况下
      else {
        return edit && this.tableData[idx];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
.el-table {
  overflow: visible !important;
}
::v-deep .el-table__body-wrapper {
  overflow-y: auto !important;
}
::v-deep .el-table__footer {
  .has-gutter {
    tr {
      td:nth-of-type(2) {
        .cell {
          padding-left: 36px;
          display: flex;
          align-items: flex-start;
          justify-content: center !important;
        }
      }
    }
  }
}
.border-input {
  border-bottom: 1px solid #e4e7ed !important;
}
::v-deep .timeColumn {
  .cell {
    height: 100%;
    display: flex;
    justify-content: center !important;
    flex-direction: column;
    flex: auto;
    padding: 0;
  }
}
::v-deep .workloadColumn {
  .cell {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: auto;
    padding: 0;
    .hours-input {
      font-size: 14px;
      padding-right: 13px;
      height: 81px;
      line-height: 81px;
      margin: 0;
      .el-input__inner {
        text-align: center;
        width: 99px;
        color: #515a6e;
        margin: 10px 0;
        border: none;
        height: 36px;
        line-height: 36px;
        background-color: #fff;
      }
    }
  }
  /*   .cell ::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -2%;
    width: 104%;
    height: 1px;
    background: #e4e7ed;
    margin-top: 0;
  } */
}

::v-deep .el-table__row {
  .el-table__cell {
    /*     padding-top: 2px;
    padding-bottom: 2px; */
    .cell {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0;
      .remark-input {
        font-size: 14px;
        margin-top: 6px;
        margin-bottom: 6px;
        color: #515a6e;
        .el-textarea__inner {
          border: none !important;
          text-align: left;
          background-color: #fff;
        }
      }
    }
  }
}
.editServiceTable {
  .el-table__header-wrapper {
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #e8eaec;
  }

  padding: 12px 0 12px;
  background: #fff;
  margin: 0 auto;
  margin-top: 0;
  overflow: hidden;
  border-radius: 2px;

  /*   ::v-deep .el-table_1_column_1 {
    .cell {
      position: absolute;
      top: 5px;
    }
  } 
  ::v-deep .el-table_1_column_1 {
    .cell {
      position: absolute;
      top: 5px;
    }
  } */

  // 假期
  .cell-date {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;

    .holidayHeader {
      position: relative;
      display: inline-block;
      text-align: center;
      width: 20px;
      height: 18px;
      text-align: center;
      background: #da4f2a;
      color: #fff;
      border-radius: 2px;
      vertical-align: middle;
      margin-left: 8px;
    }
    .holidayHeader::after {
      font-weight: normal;
      content: '休';
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .makeUpWorkDay {
      margin-left: 8px;
      position: relative;
      display: inline-block;
      text-align: center;
      width: 20px;
      height: 18px;
      color: #fff;
      background: #2d8cf0;
      border-radius: 2px;
      vertical-align: middle;
    }
    .makeUpWorkDay::after {
      font-weight: normal;
      content: '班';
      font-size: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .addSum {
      display: inline-block;
      width: 25px;
      height: 25px;
      vertical-align: middle;
      margin-left: 2px;
    }
    .addSum:hover {
      background: url('~@/assets/tabIcon/addSource.png') no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }

  /*   ::v-deep tbody {
    .el-table__row {
      .el-table_1_column_1 {
        .cell {
          padding-left: 10px;
        }
      }
    }
  } */
  ::v-deep .el-table__fixed-right {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__fixed {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__header {
    th:not(.is-hidden):last-child {
      color: #17233d;
      background: #fff;
      .cell {
        padding-bottom: 1px;
      }
    }
    /*     .has-gutter {
      tr {
        .el-table_1_column_1 {
          .cell {
            padding-left: 10px;
            top: 11px;
          }
        }
      }
    } */
  }
  .column-pointer {
    cursor: default;
  }

  @import url('@styles/element-ui.scss');
}
::v-deep .el-table__footer-wrapper {
  border-top: 1px solid #e8eaec !important;

  background: rgba(248, 248, 249, 0.5);
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;
::v-deep.show_empty .el-table__empty-text {
  display: initial;
}
::v-deep .el-table__footer {
  border-top: 1px solid #e8eaec !important;
  .has-gutter {
    .el-table__cell {
      font-size: 14px;
      background: rgba(248, 248, 249, 0.5);
      padding: 1px 0 !important;
      height: 48px !important;
      font-weight: 600;
    }
    /*     .el-table_1_column_1 {
      .cell {
        padding-left: 10px;
        top: 11px;
      }
    } */
  }
}
.workContentColumn {
  .cell {
    .editInput {
      height: 81px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      // border-bottom: 1px solid #e8eaec;

      .deleteRow {
        display: inline-block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin: 0 24px;
      }
    }
  }
}

.show_empty {
  height: 783px !important;
}
</style>
